import "./GroupBookings.css"

import { Activity } from "backend/api/activities"
import { InputPhone, InputText, Select } from "components/Inputs"
import { useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Button, Row, Title } from "styled_components"
import { WidgetColorsType } from "types/widget.type"
import MenuBookings from "widget/MenuBookings"
import {
  groupBookingsForm,
  GroupBookingsForm,
  GroupBookingsPayload,
  postGroupBookings,
} from "backend/api/bookings"
import { zodResolver } from "@hookform/resolvers/zod"
import { Step } from "widget/LandingPage"
import DataConditions from "components/PaymentForm/dataConditions/DataConditions"
import SuccessModal from "components/Modal/SuccessModal"

interface IGroupBookingsProps {
  activities: Activity[]
  handleChangeStep: (step: Step) => void
}

const defaultValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  dateReservation: "",
  messages: "",
  activityId: "",
  checkboxes: [],
}

export default function GroupBookings({ activities, handleChangeStep }: IGroupBookingsProps) {
  const { t } = useTranslation("activityList")
  const { t: tBooking } = useTranslation("bookingPayment")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const { id: widgetId } = useSelector((state: RootState) => state.widget)
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [modal, setModal] = useState(false)

  const activityCount = activities.filter((activity) => {
    const isActivityFinish =
      activity.hours.dates?.to && new Date(activity.hours.dates?.to || "") < new Date()
    return !isActivityFinish
  }).length

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    watch,
  } = useForm<GroupBookingsForm>({
    resolver: zodResolver(groupBookingsForm),
    mode: "onChange",
    defaultValues,
  })

  const activityOptions = useMemo(() => {
    return activities.map((activity) => ({
      label: activity.informations.name,
      value: activity.id.toString(),
    }))
  }, [])

  const onSubmit = async (data: GroupBookingsForm) => {
    if (!widgetId) return
    const payload: GroupBookingsPayload = {
      visitorInfos: {
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
      activityId: +data.activityId,
      widgetId: +widgetId,
      dateReservation: data.dateReservation,
      messages: data.messages,
    }
    try {
      await postGroupBookings(payload)
      setModal(true)
    } catch (error: any) {
      setError(error?.response?.data?.error)
    }
  }

  const handleCheckBoth = async () => {
    setValue("checkboxes", ["RGPD"])
  }

  return (
    <>
      <Row margin="0 0 30px 0" space mobile="flex-direction: column;" alignCenter>
        {modal && (
          <SuccessModal
            title={t("successModal", { name: watch("fullName") })}
            handleClose={() => {
              setModal(false)
              reset(defaultValues)
            }}
          />
        )}
        <Title
          style={{
            color: colors?.textColorOnBackground,
          }}
          className="notranslate"
        >
          {activityCount} {t("availableActivities")}
        </Title>
        <MenuBookings selectedMenu={2} handleChangeStep={handleChangeStep} />
        <Title
          mobile="font-size: 14px; margin-top: 5px;"
          grey
          style={{
            color: colors?.textColorOnBackground,
          }}
          className="notranslate"
        >
          {t("selectActivities")}
        </Title>
      </Row>
      <form
        className="wrapper-group-bookings"
        style={{ backgroundColor: colors?.activityCardBackgroundColor }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Select
          label={t("activityConcerned")}
          options={activityOptions}
          name="activityId"
          id="activityId"
          control={control}
          t={t}
        />
        <InputText
          control={control}
          name="fullName"
          id="fullName"
          t={t}
          label={t("firstLastName")}
        />
        <InputText
          control={control}
          name="email"
          id="email"
          inputType="email"
          t={t}
          label={t("email")}
        />
        <InputPhone
          control={control}
          name="phoneNumber"
          id="phoneNumber"
          t={t}
          label={t("phoneNumber")}
        />
        <InputText
          control={control}
          name="dateReservation"
          id="dateReservation"
          inputType="date"
          t={t}
          label={t("reservationDate")}
        />
        <InputText
          control={control}
          name="messages"
          id="messages"
          t={t}
          label={t("quoteRequest")}
          multiLine
        />
        <DataConditions
          control={control}
          t={tBooking}
          isConditionsModalOpen={isConditionsModalOpen}
          setIsConditionsModalOpen={setIsConditionsModalOpen}
          handleCheckBoth={handleCheckBoth}
          errors={errors}
          withTerms={false}
        />
        <Button
          type="submit"
          width="100%"
          style={
            isValid
              ? {
                  backgroundColor: colors?.actionBtnBackgroundColor,
                  color: colors?.actionBtnTextColor,
                }
              : {
                  color: "#5E5D5D",
                  backgroundColor: "#D9D9D9",
                }
          }
          disabled={!isValid}
          className="notranslate"
        >
          {t("sendQuoteRequest")}
        </Button>
        {error && (
          <p style={{ color: "red" }} className="notranslate">
            {error}
          </p>
        )}
      </form>
    </>
  )
}
