import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Checkbox as MuiCheckbox,
  TextField,
} from "@mui/material"
import { FormLabel } from "helpers/generalStyles"
import { MuiTelInput } from "mui-tel-input"
import React, { useMemo } from "react"
import { Control, Controller, useWatch } from "react-hook-form"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Text } from "styled_components"
import { WidgetColorsType } from "types/widget.type"

export function Select(props: {
  label?: string
  disabled?: boolean
  inputType?: "number"
  name: string
  id: string
  options: { value: string | number; label: string; disabled?: boolean }[]
  control: Control<any>
  error?: any
  t?: any
  required?: boolean
}) {
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType

  const { label, disabled, inputType, name, id, options, control, required } = props

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth={true}>
          <InputLabel
            style={{
              color: colors?.activityCardTextColor,
              backgroundColor: colors?.activityCardBackgroundColor,
            }}
            className="notranslate"
          >
            {label}
          </InputLabel>
          <MuiSelect
            {...field}
            required={required}
            label={label}
            disabled={disabled}
            id={id}
            variant="outlined"
            error={Boolean(error?.message)}
            onChange={(event) => {
              if (inputType === "number") {
                const value = event.target.value
                field.onChange(value === "" ? null : Number(value))
              } else {
                field.onChange(event)
              }
            }}
            style={{
              color: colors?.activityCardTextColor,
              backgroundColor: colors?.activityCardBackgroundColor,
            }}
            className="notranslate"
          >
            {options.map((option, index) => (
              <MenuItem
                key={index}
                disabled={option.disabled}
                value={option.value}
                style={{
                  color: colors?.activityCardTextColor,
                  backgroundColor: colors?.activityCardBackgroundColor,
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </MuiSelect>
          <FormHelperText error={Boolean(error?.message)}>
            {error?.message ? error?.message?.toString() : ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export function InputText(props: {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  id: string
  control: Control<any>
  inputType?: "number" | "decimal" | "text" | "time" | "date" | "email" | "datetime-local"
  multiLine?: boolean
  t?: any
  sx?: any
  suffix?: any
  min?: number
  max?: number
}) {
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType

  const {
    label,
    disabled,
    name,
    id,
    control,
    inputType,
    multiLine,
    t,
    sx,
    suffix,
    min,
    max,
    required,
  } = props

  const value = useWatch({
    control,
    name: name,
  })

  const isTypeNumber = inputType === "number" || inputType === "decimal"

  const willSrink = useMemo(() => {
    return inputType === "date" || inputType === "time" || inputType === "datetime-local"
  }, [inputType])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          inputProps={{
            type: isTypeNumber ? "number" : inputType,
            inputMode: isTypeNumber ? "decimal" : "text",
            min,
            step: isTypeNumber ? (inputType === "decimal" ? 0.01 : 1) : undefined,
            max,
            style: {
              color: colors?.activityCardTextColor,
              backgroundColor: colors?.activityCardBackgroundColor,
            },
          }}
          InputLabelProps={
            willSrink
              ? {
                  style: {
                    color: colors?.activityCardTextColor,
                    backgroundColor: colors?.activityCardBackgroundColor,
                  },
                  shrink: true,
                }
              : {
                  style: {
                    color: colors?.activityCardTextColor,
                    backgroundColor: colors?.activityCardBackgroundColor,
                  },
                }
          }
          sx={sx || {}}
          required={required}
          disabled={disabled}
          label={label}
          id={id}
          multiline={multiLine ? multiLine : false}
          rows={4}
          variant="outlined"
          fullWidth={true}
          type={isTypeNumber ? "decimal" : inputType}
          error={Boolean(error?.message)}
          helperText={error?.message ?? ""}
          InputProps={{
            endAdornment: suffix,
          }}
          value={value || value === 0 ? value : ""}
          onChange={(event) => {
            const value = event.target.value
            if (isTypeNumber) field.onChange(Number(value))
            else field.onChange(value)
          }}
          style={{
            color: colors?.activityCardTextColor,
            backgroundColor: colors?.activityCardBackgroundColor,
            marginInline: 0,
          }}
          className="notranslate"
        />
      )}
    />
  )
}

export function Checkbox(props: {
  id: string
  name: string
  label: string
  control: Control<any>
  required?: boolean
  disabled?: boolean
  t?: any
}) {
  const { id, name, label, control, required, disabled, t } = props
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }} className="notranslate">
          <MuiCheckbox disabled={disabled} id={id} checked={field.value} {...field} />
          {label ? <FormLabel>{`${label} ${required ? "*" : ""}`}</FormLabel> : null}
        </div>
      )}
    />
  )
}

export function InputPhone(props: {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  id: string
  control: any
  error?: any
  t: any
  style?: any
}) {
  const { label, required, disabled, name, id, control, error, t, style = {} } = props
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType

  const pattern = "^+?[1-9]d{1,3}s?d{4,14}(?:s?(?:x|ext|extension)s?d+)?$"

  return (
    <div style={style || {}}>
      <Text
        style={{
          marginLeft: "8px",
          color: colors?.activityCardTextColor,
          backgroundColor: colors?.activityCardBackgroundColor,
        }}
      >
        {label}
      </Text>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl style={{ margin: 0 }} fullWidth required={required} error={Boolean(error)}>
            <MuiTelInput
              {...field}
              disabled={disabled}
              fullWidth
              id={id}
              variant="outlined"
              inputProps={{
                pattern,
                style: {
                  color: colors?.activityCardTextColor,
                  backgroundColor: colors?.activityCardBackgroundColor,
                },
              }}
              InputLabelProps={{
                style: {
                  color: colors?.activityCardTextColor,
                  backgroundColor: colors?.activityCardBackgroundColor,
                },
              }}
              className="notranslate"
            />
            <FormHelperText className="notranslate">{error ? t(error) : ""}</FormHelperText>
          </FormControl>
        )}
      />
    </div>
  )
}
