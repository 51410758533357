import { z } from "zod"
import dayjs from "dayjs"
import "dayjs/locale/fr" // Importez la locale française
import { isArray, isEmpty, isEqual } from "lodash"
import { DATEFORMAT } from "./constants"

dayjs.locale("fr") // Utilisez la locale française

export const numberablePositiveString = z.string().refine(
  (value: string) => {
    return value !== "" && Number.isFinite(Number(value)) && Number(value) >= 0
  },
  {
    message: "Value must be a positive number",
  },
)

const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/
export const dateableString = z.string().refine((value: string) => yyyymmddRegex.test(value), {
  message: "field must be in the YYYY-MM-DD format",
})

const yyyymmddhhmmRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/
export const dateableWithTimeString = z
  .string()
  .refine((value: string) => yyyymmddhhmmRegex.test(value), {
    message: "field must be in the YYYY-MM-DD HH:mm format",
  })

export function getAuthorizedConfig(method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE") {
  const accessToken = localStorage.getItem("accessToken")
  const config = {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: "",
    data: {},
  }
  return config
}

export const pick = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)))

export const omit = (obj: any, arr: any[]): any =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)))

export interface Message {
  type: "success" | "error"
  message: string
}

export function getQueryParams(): URLSearchParams {
  return new URLSearchParams(window.location.search)
}

export function formatDate(isoDate: string) {
  return dayjs(isoDate).format("D MMMM YYYY")
}

export const googleFontLoader = (fontName: string) => {
  const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.replaceAll(
    " ",
    "+",
  )}&display=swap`

  if (document.querySelector(`link[href="${fontUrl}"]`)) {
    return
  }
  const link = document.createElement("link")
  link.rel = "stylesheet"
  link.href = fontUrl
  document.head.appendChild(link)
}

export const payzenScriptLoader = (javascriptUrl: string, publicJavascriptKey: string) => {
  if (!document.querySelector('script[src*="kr-payment-form.min.js"]') && publicJavascriptKey) {
    const script = document.createElement("script")
    script.src = javascriptUrl
    script.type = "text/javascript"
    script.setAttribute("kr-public-key", publicJavascriptKey)

    document.body.appendChild(script)
  }
}
export const removePayzenScript = () => {
  const script = document.querySelector('script[src*="kr-payment-form.min.js"]')
  if (script) {
    script.remove()
  }
}

export const isArrayEmpty = (arr: any): boolean => {
  return !isArray(arr) || isEmpty(arr)
}

export const arePropsEqual = (previousProps: any, nextProps: any) =>
  isEqual(previousProps, nextProps)

export const checkIfToday = (date: string | Date) => {
  return dayjs(date).isSame(dayjs(), "day")
}

export const getMonthStart = (
  date: string | Date,
  format: DATEFORMAT = DATEFORMAT.MONTH,
): string => {
  return dayjs(date).startOf("month").format(format)
}

export const getMonthEnd = (date: string | Date, format: DATEFORMAT = DATEFORMAT.MONTH): string => {
  return dayjs(date).endOf("month").format(format)
}

export const combineDateAndTime = (date: string, time: string): dayjs.Dayjs => {
  return dayjs(date)
    .hour(parseInt(time.split(":")[0]))
    .minute(parseInt(time.split(":")[1]))
}

export const styleTagLoader = (stylesheetUrl: string) => {
  if (!document.querySelector(`link[href="${stylesheetUrl}"]`)) {
    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = stylesheetUrl

    document.head.appendChild(link)
  }
}
