import { widgetReducer } from "reducers/widgetReducer"
import { createStore, combineReducers } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import { clientReducer } from "reducers/clientReducer"
import { platformReducer } from "reducers/platformReducer"
import { activityReducer } from "reducers/activityReducer"

const reducer = combineReducers({
  widget: widgetReducer,
  client: clientReducer,
  singleActivityPlatform: platformReducer,
  activity: activityReducer,
})
const initialState = {}
const enhancer = process.env.NODE_ENV === "development" ? composeWithDevTools() : (f: any) => f

const store = createStore(reducer, initialState, enhancer)

export default store

export type RootState = ReturnType<typeof reducer>
