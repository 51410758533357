import { z } from "zod"
import { PaymentFieldsConfig } from "./PaymentForm.constants"

const defaultT = (key: string) => key

export const extraInfoAnswerSchema = z.object({
  question: z.string(),
  answer: z.string(),
})

export type ExtraInfoAnswerType = z.infer<typeof extraInfoAnswerSchema>

export const schemaWithTranslation = (
  extraInfos: { question: string; answerRequired: boolean }[],
  t = defaultT,
) =>
  z.object({
    name: z.string().min(PaymentFieldsConfig.name.minLength, { message: t("form.name.error") }),
    email: z
      .string()
      .min(PaymentFieldsConfig.email.minLength, { message: t("form.email.error") })
      .refine((value) => !value || PaymentFieldsConfig.email.regex.test(value), {
        message: t("form.email.error"),
      }),
    phoneNumber: z
      .string()
      .min(PaymentFieldsConfig.phoneNumber.minLength, { message: t("form.phoneNumber.error") })
      .refine(
        (value) =>
          !value || (value.length >= 10 && PaymentFieldsConfig.phoneNumber.regex.test(value)),
        {
          message: t("form.phoneNumber.error"),
        },
      ),
    is_sign_in_with_google: z.boolean(),
    promotionCode: z.string().optional(),
    extraInfosAnswers: z
      .array(extraInfoAnswerSchema)
      .max(PaymentFieldsConfig.extraInfosAnswers.max)
      .optional()
      .refine(
        (answers) => {
          return extraInfos.every((extraInfo, i) => {
            return !extraInfo.answerRequired || answers?.[i]?.answer
          })
        },
        {
          message: t("form.extraInfo.error"),
        },
      ),
    checkboxes: z.array(z.string().min(PaymentFieldsConfig.checkboxes.min)).min(PaymentFieldsConfig.checkboxes.minLength, { message: t("form.checkboxes.error") }),
  })
