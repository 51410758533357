import "./Modal.css"

import { Modal } from "@mui/material"
import { Button, Title } from "styled_components"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WidgetColorsType } from "types/widget.type"

interface ISuccessModalProps {
  title: string
  handleClose: () => void
}

export default function SuccessModal({ title, handleClose }: ISuccessModalProps) {
  const { t } = useTranslation("general")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType

  return (
    <Modal open>
      <div
        className="modal-content"
        style={{
          backgroundColor: colors?.activityCardBackgroundColor,
          color: colors?.activityCardTextColor,
        }}
      >
        <Title style={{ textAlign: "center" }} size="20px" className="notranslate">
          {title}
        </Title>

        <Button
          onClick={handleClose}
          margin="25px 0 0"
          style={{
            backgroundColor: colors?.actionBtnBackgroundColor,
            color: colors?.actionBtnTextColor,
          }}
          className="notranslate"
        >
          {t("close")}
        </Button>
      </div>
    </Modal>
  )
}
