const pinnedDiscount: any = {
  en: {
    lastChance: "LAST CHANCE",
    from: "From",
    to: "to",
    minBasket: "For purchases starting at {{amount}} euros",
    enjoyDiscount: "Enjoy {{discount}}% off on {{activityName}} !",
    limitedOffer:
      "This promotion is limited to the first {{maxUseTickets}} tickets sold, so hurry up!",
    useCode: "Use the code",
  },
  fr: {
    lastChance: "DERNIERE CHANCE",
    from: "Du",
    to: "au",
    minBasket: "Dès {{amount}} euros d'achat",
    enjoyDiscount: "Profitez de {{discount}}% de réduction sur {{activityName}} !",
    limitedOffer:
      "Cette promotion est limitée aux {{maxUseTickets}} premiers tickets vendus, alors dépêchez-vous !",
    useCode: "Utilisez le code",
  },
  es: {
    lastChance: "ÚLTIMA OPORTUNIDAD",
    from: "Desde",
    to: "hasta",
    minBasket: "Para compras a partir de {{amount}} euros",
    enjoyDiscount: "¡Disfruta de un {{discount}}% de descuento en {{activityName}}!",
    limitedOffer:
      "¡Esta promoción está limitada a los primeros {{maxUseTickets}} boletos vendidos, así que apúrate!",
    useCode: "Usa el código",
  },
}

export default pinnedDiscount
