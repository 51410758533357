const general: any = {
  en: {
    badAccountConfig:
      "Please check your HoPleisure account configuration, your clientId or your widgetId passed as a parameter, as well as your Stripe account configuration on HoPleisure.",
    close: "Close",
  },
  fr: {
    badAccountConfig:
      "Veuillez vérifier votre configuration de compte HoPleisure, votre clientId ou votre widgetId passé en paramètre, ainsi que la configuration de votre compte Stripe sur HoPleisure.",
    close: "Fermer",
  },
  es: {
    badAccountConfig:
      "Por favor, verifique la configuración de su cuenta HoPleisure, su clientId o widgetId pasado como parámetro, así como la configuración de su cuenta Stripe en HoPleisure.",
    close: "Cerrar",
  },
}

export default general
