import { WIDGET_UPLOAD_SUCCESS } from "constants/widgetConstants"
import { WidgetType } from "types/widget.type"

export function widgetReducer(
  state = {},
  action: any,
): WidgetType["widgets"] | Record<string, never> {
  const { type, payload } = action
  switch (type) {
    case WIDGET_UPLOAD_SUCCESS:
      return payload as WidgetType["widgets"]

    default:
      return state
  }
}
