const dateTicketPicker: any = {
  en: {
    previous: "Previous",
    next: "Next",
    bookingInProgress: "Booking in progress",
    ticketSelection: "Ticket selection",
    selectDate: "Select a date",
    noAvailableSlot: "No available slot for it's date, please select another date.",
    selectSlot: "Select a slot",
    noAvailableSlots: "No available slots",
    availablePlaces: "Available places",
    availableGroups: "Available groups",
    hour: "Hour",
    duration: "Duration",
    validate: "Validate",
    DEFAULT: "Standard price",
    SENIOR: "Child price",
    CHILD: "Senior price",
    personsNumberInGoup: "Number of person(s) in the group",
    soldOut: "SOLD-OUT",
    showAvailabilities: "Check available dates",
    pricePerson: "Price / person",
    priceTier: "Price - {{min}} to {{max}} people",
  },
  fr: {
    previous: "Précédent",
    next: "Suivant",
    bookingInProgress: "Réservation en cours",
    ticketSelection: "Sélection de votre billet",
    selectDate: "Sélectionnez une date",
    noAvailableSlot:
      "Pas de créneaux disponibles pour cette date, veuillez sélectionner une autre date.",
    selectSlot: "Sélectionnez un créneau",
    noAvailableSlots:
      "Aucun créneau disponible pour cette date, veuillez sélectionner une autre date.",
    noPlace: "Aucune place disponible",
    availablePlaces: "Places disponibles",
    availableGroups: "Groupes disponibles",
    hour: "Heure",
    duration: "Durée",
    validate: "Valider",
    DEFAULT: "Tarif Plein",
    SENIOR: "Tarif Senior",
    CHILD: "Tarif Enfant",
    personsNumberInGoup: "Nombre de personne(s) dans le groupe",
    soldOut: "COMPLET",
    showAvailabilities: "Verifier les dates disponibles",
    pricePerson: "Prix / pers",
    priceTier: "Prix - {{min}} à {{max}} pers",
  },
  es: {
    previous: "Anterior",
    next: "Siguiente",
    bookingInProgress: "Reserva en progreso",
    ticketSelection: "Selección de boletos",
    selectDate: "Selecciona una fecha",
    noAvailableSlot:
      "No hay espacios disponibles para esta fecha, por favor selecciona otra fecha.",
    selectSlot: "Selecciona un espacio",
    noAvailableSlots: "No hay espacios disponibles, por favor selecciona otra fecha.",
    noPlace: "No hay plazas disponibles",
    availablePlaces: "Plazas disponibles",
    availableGroups: "Grupos disponibles",
    hour: "Hora",
    duration: "Duración",
    validate: "Validar",
    DEFAULT: "Precio estándar",
    SENIOR: "Precio para niños",
    CHILD: "Precio para mayores",
    personsNumberInGoup: "Número de persona(s) en el grupo",
    soldOut: "AGOTADO",
    showAvailabilities: "Consultar fechas disponibles",
    pricePerson: "Precio / pers",
    priceTier: "Precio - {{min}} a {{max}} pers",
  },
}

export default dateTicketPicker
