const activityList: any = {
  en: {
    availableActivities: "available activities",
    selectActivities: "Select an activity",
    validateReservation: "Validate reservation",
    eventFinished: "Event finished",
    outOfStock: "Out of stock",
    noTemporaryActivity: "Open all year",
    from: "From",
    to: "to",
    hour: "Hour",
    duration: "Duration",
    select: "Add to selection",
    selected: "Selected",
    cancellationPolicy: "Cancellation policy",
    book: "Book",
    individualBookings: "Individual reservations",
    groupBookings: "Privatization / quotes / Groups",
    modifiedBookings: "Modify / Cancel a reservation",
    languages: "Languages",
    activityConcerned: "Activity concerned",
    firstLastName: "First and last name",
    email: "Email",
    phoneNumber: "Phone number",
    reservationDate: "Reservation date",
    quoteRequest: "Your quote request",
    sendQuoteRequest: "Send the quote request",
    successModal: "Thank you {{name}}, your quote request has been sent successfully!",
    comingSoon:
      "This feature will be available very soon, we are wrapping up its development. For now, you can edit or cancel your booking by contacting us : {{email}}.",
    English: "English",
    French: "Français",
    Spanish: "Español",
  },
  fr: {
    availableActivities: "activités disponibles",
    selectActivities: "Sélectionnez une activité",
    validateReservation: "Valider Réservation",
    eventFinished: "Évènement terminé",
    outOfStock: "Plus de places disponibles",
    noTemporaryActivity: "Ouvert toute l'année",
    from: "Du",
    to: "au",
    hour: "Heure",
    duration: "Durée",
    select: "Sélectionner",
    selected: "Sélectionné",
    cancellationPolicy: "Politique d'annulation",
    book: "Réserver",
    individualBookings: "Réservations individuelles",
    groupBookings: "Privatisation / devis / Groupes",
    modifiedBookings: "Modifier / Annuler une réservation",
    languages: "Langues",
    activityConcerned: "Activité concernée",
    firstLastName: "Nom et prénom",
    email: "Email",
    phoneNumber: "Numéro de téléphone",
    reservationDate: "Date de réservation",
    quoteRequest: "Votre demande de devis",
    sendQuoteRequest: "Envoyer la demande de devis",
    successModal: "Merci {{name}}, votre demande de devis a été envoyée avec succès !",
    comingSoon:
      "Cette fonctionnalité sera disponible d'ici quelques temps, nous finalisons son développement. En attendant, pour annuler ou modifier votre réservation, vous pouvez nous contacter à l'adresse suivante : {{email}}.",
    English: "English",
    French: "Français",
    Spanish: "Español",
  },
  es: {
    availableActivities: "actividades disponibles",
    selectActivities: "Selecciona una actividad",
    validateReservation: "Validar reserva",
    eventFinished: "Evento terminado",
    outOfStock: "Sin disponibilidad",
    noTemporaryActivity: "Abierto todo el año",
    from: "Desde",
    to: "hasta",
    hour: "Hora",
    duration: "Duración",
    select: "Añadir a la selección",
    selected: "Seleccionado",
    cancellationPolicy: "Política de cancelación",
    book: "Reservar",
    individualBookings: "Reservas individuales",
    groupBookings: "Privatización / presupuestos / Grupos",
    modifiedBookings: "Modificar / Cancelar una reserva",
    languages: "Idiomas",
    activityConcerned: "Actividad concernida",
    firstLastName: "Nombre y apellido",
    email: "Correo electrónico",
    phoneNumber: "Número de teléfono",
    reservationDate: "Fecha de reserva",
    quoteRequest: "Tu solicitud de presupuesto",
    sendQuoteRequest: "Enviar solicitud de presupuesto",
    successModal: "Gracias {{name}}, ¡tu solicitud de presupuesto ha sido enviada con éxito!",
    comingSoon:
      "Esta función estará disponible muy pronto, estamos finalizando su desarrollo. Mientras tanto, puedes editar o cancelar tu reserva contactándonos a : {{email}}.",
    English: "English",
    French: "Français",
    Spanish: "Español",
  },
}

export default activityList
