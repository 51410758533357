import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Button, Row } from "styled_components"
import { WidgetColorsType } from "types/widget.type"
import { GroupQuoteStatus } from "widget/GroupBookings/GroupBookings.types"
import { Step } from "widget/LandingPage"

type Menu = {
  id: number
  title: string
  step?: Step
  isShow: boolean
}

interface IMenuBookingsProps {
  handleChangeStep: (step: Step) => void
  selectedMenu: number
}

export default function MenuBookings({ selectedMenu, handleChangeStep }: IMenuBookingsProps) {
  const { t } = useTranslation("activityList")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const { group_quotes_status, canManageBooking } = useSelector((state: RootState) => state.widget)

  const menuList = useMemo<Menu[]>(
    () => [
      {
        id: 1,
        title: t("individualBookings"),
        step: "activityList",
        isShow: true,
      },
      {
        id: 2,
        title: t("groupBookings"),
        step: "groupBooking",
        isShow: group_quotes_status === GroupQuoteStatus.ENABLED,
      },
      {
        id: 3,
        title: t("modifiedBookings"),
        step: "cancelBooking",
        isShow: Boolean(canManageBooking),
      },
    ],
    [t, group_quotes_status, canManageBooking],
  )

  return (
    <Row gap="12px" mobile="flex-wrap: wrap; justify-content: center;">
      {menuList.map((menu) => {
        if (!menu.isShow) {
          return null
        }
        return (
          <Button
            key={menu.id}
            style={
              selectedMenu === menu.id
                ? {
                    color: colors?.actionBtnTextColor,
                    backgroundColor: colors?.actionBtnBackgroundColor,
                  }
                : {
                    color: "#5E5D5D",
                    backgroundColor: "#D9D9D9",
                  }
            }
            onClick={() => {
              if (menu.step) {
                handleChangeStep(menu.step)
              }
            }}
            className="notranslate"
          >
            {menu.title}
          </Button>
        )
      })}
    </Row>
  )
}
