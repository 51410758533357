import { theme } from "helpers/constants"
import styled from "styled-components"

export const Title = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !["bold", "grey", "size", "margin", "underline", "pointer", "width", "mobile"].includes(prop),
})<{
  bold?: boolean
  grey?: boolean
  size?: string | number
  margin?: string
  underline?: boolean
  pointer?: boolean
  width?: string
  mobile?: string
}>`
  font-size: ${({ size }) => size || "18px"};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ grey }) => (grey ? "grey" : "black")};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || "0"};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  width: ${({ width }) => width || "auto"};

  ${({ mobile }) => theme.mediaQueries.mobile} {
    ${({ mobile }) => mobile || ""}
  }
`

export const Text = styled.p.withConfig({
  shouldForwardProp: (prop) =>
    !["bold", "grey", "white", "size", "margin", "underline", "pointer"].includes(prop),
})<{
  bold?: boolean
  grey?: boolean
  white?: boolean
  size?: string | number
  margin?: string
  underline?: boolean
  pointer?: boolean
}>`
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ grey, white }) => (grey ? theme.color.grey : white ? "white" : "black")};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || "0"};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
`

export const Link = styled.a.withConfig({
  shouldForwardProp: (prop) => !["bold", "grey", "size", "color", "margin"].includes(prop),
})<{
  bold?: boolean
  grey?: boolean
  size?: string | number
  color?: string
  margin?: string
}>`
  font-size: ${({ size }) => size || "14px"};
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  color: ${({ color }) => color || theme.color.primary};
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || "0"};
  text-decoration: underline;
  cursor: pointer;
`

export const Row = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "start",
      "end",
      "center",
      "space",
      "alignStart",
      "alignEnd",
      "alignCenter",
      "alignSpace",
      "margin",
      "width",
      "mobile",
      "gap",
    ].includes(prop),
})<{
  start?: boolean
  end?: boolean
  center?: boolean
  space?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  alignSpace?: boolean
  margin?: string
  width?: string
  mobile?: string
  gap?: string
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: ${({ start, end, center, space }) =>
    start
      ? "flex-start"
      : end
        ? "flex-end"
        : center
          ? "center"
          : space
            ? "space-between"
            : "flex-start"};
  align-items: ${({ alignStart, alignEnd, alignCenter, alignSpace }) =>
    alignStart
      ? "flex-start"
      : alignEnd
        ? "flex-end"
        : alignCenter
          ? "center"
          : alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${({ margin }) => margin || "0"};
  width: ${({ width }) => width || "auto"};
  gap: ${({ gap }) => gap || "0"};
  ${({ mobile }) => theme.mediaQueries.mobile} {
    ${({ mobile }) => mobile || ""}
  }
`

export const Column = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      "start",
      "end",
      "center",
      "space",
      "alignStart",
      "alignEnd",
      "alignCenter",
      "alignSpace",
      "margin",
      "width",
      "mobile",
      "tablet",
    ].includes(prop),
})<{
  start?: boolean
  end?: boolean
  center?: boolean
  space?: boolean
  alignStart?: boolean
  alignEnd?: boolean
  alignCenter?: boolean
  alignSpace?: boolean
  margin?: string
  width?: string
  mobile?: string
  tablet?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ start, end, center, space }) =>
    start
      ? "flex-start"
      : end
        ? "flex-end"
        : center
          ? "center"
          : space
            ? "space-between"
            : "flex-start"};
  align-items: ${({ alignStart, alignEnd, alignCenter, alignSpace }) =>
    alignStart
      ? "flex-start"
      : alignEnd
        ? "flex-end"
        : alignCenter
          ? "center"
          : alignSpace
            ? "space-between"
            : "flex-start"};
  margin: ${({ margin }) => margin || "0"};
  width: ${({ width }) => width || "auto"};

  ${({ mobile }) => theme.mediaQueries.mobile} {
    ${({ mobile }) => mobile || ""}
  }
  ${({ tablet }) => theme.mediaQueries.tablet} {
    ${({ tablet }) => tablet || ""}
  }
`

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !["secondary", "width", "margin"].includes(prop),
})<{
  secondary?: boolean
  width?: string
  margin?: string
  disabled?: boolean
}>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; // Space between icon and text
  position: relative;
  width: auto;
  height: 46px;
  padding: 20px 20px;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: ${({ margin }) => margin || "0"};
  color: ${({ secondary }) => (secondary ? theme.color.primary : "#ffffff")};
  border: ${({ secondary }) => (secondary ? `1px solid ${theme.color.primary}` : "none")};
  background: ${({ secondary }) => (secondary ? "#ffffff" : theme.color.primary)};
  width: ${({ width }) => width || "auto"};
  & font {
    color: inherit;
    background-color: inherit;
    white-space: nowrap;
  }
`
