import {
  CLIENT_ID_UPLOAD_SUCCESS,
  CLIENT_PAYMENT_ACCOUNT_UPLOAD_SUCCESS,
} from "constants/widgetConstants"
import { defaultGoogleFontUrl } from "helpers/constants"
import { styleTagLoader } from "helpers/helpers"

const getClientIdFromParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const clientId = urlParams.get("clientId") || ""
  if (clientId) styleTagLoader(defaultGoogleFontUrl)
  return clientId
}

export function clientReducer(
  state = {
    CLIENT_ID: getClientIdFromParams(),
    accountId: "",
    paymentPlatform: "",
    javascriptUrl: "",
    publicJavascriptKey: "",
  },
  action: any,
) {
  const { type, payload } = action
  switch (type) {
    case CLIENT_ID_UPLOAD_SUCCESS:
      return { ...state, CLIENT_ID: payload }
    case CLIENT_PAYMENT_ACCOUNT_UPLOAD_SUCCESS:
      return { ...state, ...payload }
    default:
      return state
  }
}
