export const PaymentFieldsConfig = {
  name: {
    minLength: 5,
  },
  email: {
    minLength: 4,
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  },
  phoneNumber: {
    minLength: 10,
    regex: /^[\d+\s]+$/,
  },

  extraInfosAnswers: {
    max: 3,
  },
  checkboxes: {
    min: 3,
    minLength: 2,
  },
}
