import { Checkbox, FormHelperText } from "@mui/material"
import { Controller } from "react-hook-form"
import ConditionsModal from "../conditionsModal/ConditionsModal"
import React from "react"
import { arePropsEqual } from "helpers/helpers"
import "./DataConditions.css"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WidgetColorsType } from "types/widget.type"

const DataConditions = (props: {
  control: any
  setIsConditionsModalOpen: (isOpen: boolean) => void
  isConditionsModalOpen: boolean
  t: (key: string) => string
  handleCheckBoth: () => void
  errors: any
  withTerms?: boolean
}) => {
  const {
    control,
    setIsConditionsModalOpen,
    isConditionsModalOpen,
    t,
    handleCheckBoth,
    errors,
    withTerms = true,
  } = props
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType

  return (
    <div className="condition-checkboxes-container">
      <Controller
        name="checkboxes"
        control={control}
        render={({ field }) => {
          const isChecked = field.value.includes("RGPD")
          return (
            <div className="checkbox-label-container">
              <Checkbox
                required
                checked={isChecked}
                onChange={(e) => {
                  const newValue = [...field.value]
                  if (e.target.checked) {
                    newValue.push("RGPD")
                  } else {
                    const idx = newValue.indexOf("RGPD")
                    newValue.splice(idx, 1)
                  }
                  field.onChange(newValue)
                }}
                style={{
                  color: colors?.activityCardTextColor,
                  backgroundColor: colors?.activityCardBackgroundColor,
                }}
              />
              <label
                style={{
                  color: colors?.activityCardTextColor,
                  backgroundColor: colors?.activityCardBackgroundColor,
                }}
                className="notranslate"
              >
                {t("readAndAccept")}{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsConditionsModalOpen(true)}
                >
                  {t("processingConditions")}
                </span>
                .
              </label>
            </div>
          )
        }}
      />
      {withTerms && (
        <Controller
          name="checkboxes"
          control={control}
          render={({ field }) => {
            const isChecked = field.value.includes("Terms")
            return (
              <div className="checkbox-label-container">
                <Checkbox
                  required
                  checked={isChecked}
                  onChange={(e) => {
                    const newValue = [...field.value]
                    if (e.target.checked) {
                      newValue.push("Terms")
                    } else {
                      const idx = newValue.indexOf("Terms")
                      newValue.splice(idx, 1)
                    }
                    field.onChange(newValue)
                  }}
                  style={{
                    color: colors?.activityCardTextColor,
                    backgroundColor: colors?.activityCardBackgroundColor,
                  }}
                />
                <label
                  style={{
                    color: colors?.activityCardTextColor,
                    backgroundColor: colors?.activityCardBackgroundColor,
                  }}
                  className="notranslate"
                >
                  {t("accept")}{" "}
                  <span
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => setIsConditionsModalOpen(true)}
                  >
                    {t("salesConditions")}
                  </span>
                  .
                </label>
              </div>
            )
          }}
        />
      )}
      {errors.checkboxes && (
        <FormHelperText error className="notranslate">
          {errors.checkboxes.message}
        </FormHelperText>
      )}
      <ConditionsModal
        t={t}
        isConditionsModalOpen={isConditionsModalOpen}
        setIsConditionsModalOpen={setIsConditionsModalOpen}
        handleCheckBoth={handleCheckBoth}
      />
    </div>
  )
}

export default React.memo(DataConditions, arePropsEqual)
