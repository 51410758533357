import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import activityList from "./activityList"
import dateTicketPicker from "./dateTicketPicker"
import bookingPayment from "./bookingPayment"
import error from "./error"
import general from "./general"
import pinnedDiscount from "./pinnedDiscount"

const resources = {
  en: {
    general: general.en,
    activityList: activityList.en,
    dateTicketPicker: dateTicketPicker.en,
    bookingPayment: bookingPayment.en,
    error: error.en,
    pinnedDiscount: pinnedDiscount.en,
  },
  fr: {
    general: general.fr,
    activityList: activityList.fr,
    dateTicketPicker: dateTicketPicker.fr,
    bookingPayment: bookingPayment.fr,
    error: error.fr,
    pinnedDiscount: pinnedDiscount.fr,
  },
  es: {
    general: general.es,
    activityList: activityList.es,
    dateTicketPicker: dateTicketPicker.es,
    bookingPayment: bookingPayment.es,
    error: error.es,
    pinnedDiscount: pinnedDiscount.es,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["en", "fr", "es"],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["navigator", "localStorage", "sessionStorage", "cookie"],
      caches: ["localStorage", "cookie"],
    },
  })

export default i18n
