import styled from "styled-components";

export const HopleisureLink = styled.a`
    color: #2CA58D;
    text-decoration: underline;
    cursor: pointer;
`;

export const HopleisureTextButton = styled.div`
    color: #2CA58D;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
`;

export const AuthTitle = styled.h1`
    font-size: 50px;
    font-weight: 800;
    text-align: center;
`;

export const MainTitleText =  styled.h1`
    text-align: center;
    font-size: 30px;
    margin: 0;
`;

export const SecondaryTitleText =  styled.h2`
    text-align: center;
    font-size: 24px;
    margin: 10px;
`;

export const ThirdTitleText =  styled.h3`
    text-align: center;
    font-size: 24px;
    margin: 10px;
    color: #4FD1C5;
`;

export const FourthTitleText =  styled.h4`
    text-align: center;
    font-size: 20px;
    margin: 10px;
    color: #004643;
`;

export const FormDiv = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    gap: 30px;
    padding: 30px;
`;


export const ActivityCard = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-radius: 30px;
  -webkit-box-shadow: 5px 5px 11px -3px #BABABA; 
  box-shadow: 5px 5px 11px -3px #666666;
`;

export const FormBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const FormLabel = styled.div`
    flex: 1;
    font-weight: 800;
    min-width: 150px;
`;    

export const FormErrorHelper = styled.div`
    color: rgb(211, 47, 47);
    font-size: 0.8571428571428571rem;
`;    

export const GreenBoxWithTitle = styled.div`
    color: white;
    background-color: #2CA58D;
    font-weight: 800;
    font-size: 24px;
    width: 432px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;    
