import "./ManageBookings.css"

import { Activity } from "backend/api/activities"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Row, Title } from "styled_components"
import { WidgetActivity } from "types/activity.type"
import { WidgetColorsType } from "types/widget.type"
import { Step } from "widget/LandingPage"
import MenuBookings from "widget/MenuBookings"

interface IManageBookingsProps {
  activities: Activity[]
  handleChangeStep: (step: Step) => void
}

export default function ManageBookings({ activities, handleChangeStep }: IManageBookingsProps) {
  const { t } = useTranslation("activityList")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const activity = useSelector((state: RootState) => state.activity) as WidgetActivity[] | null

  const activityCount = activities.filter((activity) => {
    const isActivityFinish =
      activity.hours.dates?.to && new Date(activity.hours.dates?.to || "") < new Date()
    return !isActivityFinish
  }).length

  return (
    <>
      <Row margin="0 0 30px 0" space mobile="flex-direction: column;" alignCenter>
        <Title
          style={{
            color: colors?.textColorOnBackground,
          }}
          className="notranslate"
        >
          {activityCount} {t("availableActivities")}
        </Title>
        <MenuBookings selectedMenu={3} handleChangeStep={handleChangeStep} />
        <Title
          mobile="font-size: 14px; margin-top: 5px;"
          grey
          style={{
            color: colors?.textColorOnBackground,
          }}
          className="notranslate"
        >
          {t("selectActivities")}
        </Title>
      </Row>
      <form
        className="wrapper-cancel-bookings"
        style={{ backgroundColor: colors?.activityCardBackgroundColor }}
      >
        <p
          style={{
            color: colors?.activityCardTextColor,
            backgroundColor: colors?.activityCardBackgroundColor,
          }}
          className="notranslate"
        >
          {t("comingSoon", {
            email: activity?.[0]?.activityEmail ?? activities?.[0]?.informations.email,
          })}
        </p>
      </form>
    </>
  )
}
