const error: any = {
  en: {
    errorPageTitle: "This page does not exist",
    errorPageSubTitle: "Follow this link to go back to homepage",
    errorButtonLabel: "Click",
    MARK_AS_FULL: "The slot is marked as full, please remove this restriction and retry",
    UNAVAILABLE: "The slot is marked as canceled, reopen it and retry",
    NOT_AVAILABLE_SLOT: "This slot is no longer available",
    NOT_ENOUGHT_PLACES_ON_SLOT:
      "There are not enough places on the slot, retry on another slot or decrease the number of people",
    DATE_IN_THE_PAST: "The start date of the slot is in the past, retry with a valid slot",
    NOT_FOUND: "Data not found",
    INTERNAL_SERVOR_ERROR: "Internal server error, please contact us",
    actions: {
      backToEventsList: "Back to events list",
    },
  },
  fr: {
    errorPageTitle: "Cette page n'existe pas",
    errorPageSubTitle: "Suivez ce lien pour retourner vers la page principale",
    errorButtonLabel: "Cliquez",
    MARK_AS_FULL:
      "Le créneau est indiqué comme plein, veuillez enlever la restriction et réessayer",
    UNAVAILABLE: "Le créneau est indiqué comme fermé, veuillez le réouvrir et réessayer",
    NOT_AVAILABLE_SLOT: "Le créneau n'est pas disponible",
    NOT_ENOUGHT_PLACES_ON_SLOT: "Il n'y a plus assez de places sur le créneau",
    DATE_IN_THE_PAST:
      "Le créneau indiqué a une date de début dans le passé, veuillez réessayer avec un créneau correct",
    NOT_FOUND: "Donnée non trouvée",
    INTERNAL_SERVOR_ERROR: "Erreur interne, veuillez nous contacter",
    actions: {
      backToEventsList: "Retour à la liste des événements",
    },
  },
  es: {
    errorPageTitle: "Esta página no existe",
    errorPageSubTitle: "Siga este enlace para volver a la página de inicio",
    errorButtonLabel: "Haga clic",
    MARK_AS_FULL:
      "El espacio está marcado como completo, elimine esta restricción y vuelva a intentarlo",
    UNAVAILABLE: "El espacio está marcado como cancelado, reabrálo y vuelva a intentarlo",
    NOT_AVAILABLE_SLOT: "Este espacio ya no está disponible",
    NOT_ENOUGHT_PLACES_ON_SLOT:
      "No hay suficientes lugares en el espacio, intente otro espacio o reduzca el número de personas",
    DATE_IN_THE_PAST:
      "La fecha de inicio del espacio es en el pasado, intente con un espacio válido",
    NOT_FOUND: "Datos no encontrados",
    INTERNAL_SERVOR_ERROR: "Error interno, por favor contáctenos",
    actions: {
      backToEventsList: "Volver a la lista de eventos",
    },
  },
}

export default error
